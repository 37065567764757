<template>
  <div class="text-center mt-2">
    <div class="d-block mx-auto chart-data">
      <div id="gauge-validation-1" />
      <vue-gauge refid="gauge-validation-1" :options="options" />
      <cilinder-chat :total="total.grade" />

      <h5 class="mt-3 text-center">Peso: {{ total.weight * 100 }}%</h5>
    </div>

    <b-table :items="indicators" :fields="fields" striped small>
      <template #cell(action)="data">
        <b-button @click="data.toggleDetails" size="sm" variant="icon">
          <b-icon-chevron-down v-if="data.item._showDetails" />
          <b-icon-chevron-left v-else />
        </b-button>
      </template>
      <template slot="row-details" slot-scope="data">
        <div class="px-3 pb-3">
          <list-indicators
            :template="{ indicators: [data.item] }"
            :sub_module="sub"
            :indicator_module="modules"
          />
        </div>
      </template>
    </b-table>

    <!-- <div class="w-100 d-block">
      <b-table bordered small :fields="fields" :items="indicators" responsive>
        <template v-slot:custom-foot>
          <tr>
            <th>Total</th>
            <th>{{ total.weight }}</th>
            <th></th>
            <th></th>
            <th>{{ total.grade }}</th>
          </tr>
        </template>
      </b-table>
    </div> -->
  </div>
</template>
<script>
import CilinderChat from "@/components/CilinderChat";
import ListIndicators from "@/pages/Homologation/Services/Validation/Admin/Validation/ListIndicators";
import VueGauge from "vue-gauge";
import { mapGetters } from "vuex";

export default {
  components: {
    ListIndicators,
    VueGauge,
    CilinderChat,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modules: {
        id: 1,
        name: "Econômico Financeiro",
        need_attention: true,
        children: [
          {
            name: "Lançamento de rendimentos",
            url: "balance-sheet",
            status: 3,
            need_attention: true,
          },
          {
            name: "Indicadores financeiros",
            url: "income",
            status: 3,
            need_attention: false,
          },
        ],
        base: "financial",
        progress: 100,
        description:
          "Foco na Análise de Líquidez de Curto, Envidamento Bancário e Fiscal, Cobertura de Provisões Férias e 13o. Salário,  Dependência Econômica, Situção Fiscal. Esse fatores são determinantes na análise e no resultado do nosso trabalho",
        icon: "budget",
        enabled: true,
      },
      sub: {
        name: "Lançamento de rendimentos",
        url: "balance-sheet",
        status: 3,
        need_attention: true,
      },
      total: {},
      options: {},
      indicators: [],
      fields: [
        {
          key: "name",
          label: "Indicadores",
        },
        {
          key: "weight",
          label: "Peso",
        },
        {
          key: "result",
          label: "Indicador",
          formatter: (value) => value.toFixed(2),
        },
        {
          key: "score",
          label: "Pontos",
        },
        {
          key: "grade",
          label: "Ponderação",
        },
        {
          key: "action",
          label: "Indicadores",
        },
      ],
    };
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
  },

  methods: {
    getOptions() {
      if (this.total) {
        const total = this.total;
        const value = total?.grade ? total.grade : 0;
        this.options = {
          needleColor: "black",
          chartWidth: 400,
          needleStartValue: value,
          arcDelimiters: [20, 40, 60, 80],
          arcLabels: [2, 4, 6, 8],
          rangeLabel: ["0", "10"],
          hasNeedle: true,
          arcColors: ["#ff3300", "#f84438", "#e9c31b", "#b7ec82", "#589c14"],
          centralLabel: `${value}`,
          needleValue: value * 10,
        };
      }
    },
  },
  watch: {
    data: {
      handler(data) {
        if (data.indicators && data.total) {
          this.indicators = data.indicators.map((indicator) => ({
            ...indicator,
            grade: indicator.grade.toFixed(2),
          }));
          this.total = data.total;
          this.getOptions();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["is_mobile"]),
  },
};
</script>
